import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import HeaderWrapper from '../components/HeaderWrapper';
import HoverEffect from '../components/HoverEffect';

export default function Projects({ data, location }) {
	const { allMarkdownRemark } = data;
	const { nodes } = allMarkdownRemark;
	const filteredNodes = nodes.filter((node) =>
		node.frontmatter.language.includes('bg')
	);
	return (
		<Layout location={location} lang="bg">
			<Seo title="Projects" />
			<HeaderWrapper
				title="Проекти"
				featuredImageUrl="/static/8dccc999ebe7c570ef99c265d423a6f9/5267c/livingroom-cam-1.jpg"
			></HeaderWrapper>
			<div className="grid grid-cols-1 md:grid-cols-2">
				{filteredNodes.map((node) => (
					<HoverEffect
						key={node.id}
						slug={node.frontmatter.slug}
						image={
							node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
						}
						title={node.frontmatter.title}
					/>
				))}
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query {
		allMarkdownRemark(
			filter: { html: { ne: "" }, frontmatter: { language: { ne: null } } }
			sort: { fields: frontmatter___featuredImage___birthTime, order: DESC }
		) {
			nodes {
				id
				frontmatter {
					title
					language
					location
					size
					timeframe
					slug
					featuredImage {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			}
		}
	}
`;
